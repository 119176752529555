import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'

import { CustomTheme } from '@styles/theme'
import { CustomFonts } from '@styles/fonts'

export const wrapRootElement = ({ element }) => <>{element}</>

export const wrapPageElement = ({ element }) => (
  <ChakraProvider theme={CustomTheme}>
    <CustomFonts />
    {element}
  </ChakraProvider>
)
