import { extendTheme } from '@chakra-ui/react'

const colors = {
  brand: {
    grey: '#d9d8d6',
    mist: '#a5bac9',
    navy: '#243746',
    red: '#b2292e',
  },
  fjNavy: {
    100: '#ffffff',
    500: '#243746',
  },
  fjRed: {
    100: '#ffffff',
    500: '#b2292e',
  },
}

const fonts = {
  body: '"Futura Std", system-ui, sans-serif',
  heading: '"Futura Std", system-ui, sans-serif',
  monospace: 'Menlo, monospace',
}

const fontWeights = {
  body: '400',
  bold: '700',
  heading: '700',
  light: '300',
}

const textStyles = {
  /* title */
  h1: {
    fontSize: {
      base: 'clamp(2rem, 1.5000rem + 2.5000vw, 4.5rem)',
      xl: 'clamp(2.625rem, -1.1250rem + 4.6875vw, 4.5rem)',
    },
    fontWeight: '700',
    lineHeight: '120%',
  },
  /* subtitle */
  h2: {
    fontSize: 'clamp(1.5rem, 1.4000rem + 0.5000vw, 2rem)',
    fontWeight: '700',
    lineHeight: '120%',
  },
  /* heading */
  h3: {
    fontSize: 'clamp(1rem, 0.9286rem + 0.3571vw, 1.5rem)',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  /* subheading */
  h4: {
    fontSize: {
      base: 'clamp(1.25rem, 1.1000rem + 0.7500vw, 2rem)',
      xl: 'clamp(1.125rem, 1.0357rem + 0.4464vw, 1.75rem)',
    },
    fontWeight: '300',
    lineHeight: '120%',
  },
  body: {
    fontSize: {
      base: 'clamp(1rem, 0.9500rem + 0.2500vw, 1.25rem)',
      xl: 'clamp(1rem, -0.7069rem + 1.3793vw, 1.5rem)',
    },
    fontWeight: '300',
    lineHeight: '120%',
  },
  footnote: {
    fontSize: {
      base: '1rem',
      xl: 'clamp(0.875rem, 0.0216rem + 0.6897vw, 1.125rem)',
    },
    fontWeight: '400',
    lineHeight: '1.2em',
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  menu: {
    fontSize: 'clamp(0.75rem, 0.2155rem + 0.8621vw, 1.25rem)',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  statLabel: {
    fontSize: 'clamp(1rem, 0.9750rem + 0.1250vw, 1.175rem)',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '0.2em',
    textTransform: 'uppercase',
  },
  statValue: {
    fontSize: 'clamp(1.75rem, 1.4286rem + 1.6071vw, 4rem)',
    fontWeight: '700',
    lineHeight: '120%',
  },
}

export const CustomTheme = extendTheme({
  colors,
  fonts,
  fontWeights,
  textStyles,
})
