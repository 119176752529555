import React from 'react'
import { Global } from '@emotion/react'

export const CustomFonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Futura Std';
        src: url('/fonts/FuturaStd-Bold.woff2') format('woff2'),
          url('/fonts/FuturaStd-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
          U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
          U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Futura Std';
        src: url('/fonts/FuturaStd-Book.woff2') format('woff2'),
          url('/fonts/FuturaStd-Book.woff') format('woff');
        font-weight: 400 600;
        font-style: normal;
        font-display: swap;
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
          U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
          U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Futura Std';
        src: url('/fonts/FuturaStd-Light.woff2') format('woff2'),
          url('/fonts/FuturaStd-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
          U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
          U+FEFF, U+FFFD;
      }
      `}
  />
)
